import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";

import { Content } from "page_components/apartments";

const NotFoundPage = () => {
	return (
		<Layout>
			<Content />
		</Layout>
	);
};

export const Head = () => <Seo title="Apartamenty" />;

export default NotFoundPage;
