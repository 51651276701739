import "./styles.scss";

import React from "react";
import { Helmet } from "react-helmet";

const Content = () => {
	return (
		<>
			<Helmet>
				<script
					src="https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/js/main.js"
					onLoad="(function(){SM.init('c1c389e9-dc8a-4395-8329-e2cea4aabdf6', 'smart-makieta', { '--color-main': '#9a7f5f' }, {showPrice: true, autoscrollDesktop: true, autoOpenOnMobile: true})})()"
				/>
			</Helmet>
			<div className="apartments-content">
				<div className="apartments-content__header">
					<div className="apartments-content__header-shape" />
					<div className="container">
						<h1>Apartamenty</h1>
					</div>
				</div>

				<div id="smart-makieta"></div>
			</div>
		</>
	);
};

export default Content;
